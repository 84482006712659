import { Helmet } from 'react-helmet';
import projectsList from '../data/projects';
import SectionPageTitle from '../components/SectionPageTitle';
import ProjectOverlay from '../components/ProjectOverlay';
import SectionContact from '../components/SectionContact';

export default function Projects() {
	return (
		<>
			<Helmet>
				<title>WebServices, mes projets</title>
				<meta name="description" content="Bienvenue sur le site WebServices, je peut vous creer des sites web et des services en ligne" />
				<meta name="keywords" content="developpement, web , ecommerce, saas, creation, site internet, site e-commerce" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Projets'}
				subTitle={'Mes créations'}
			/>

			<section className="big-container">
				<div className="grid gtc-3 g-3">
					{projectsList.map((project, idx) =>
						<ProjectOverlay
							key={idx}
							img={project.img}
							projectName={project.name}
							category={project.category}
							url={project.url}
						/>
					)}
				</div>
			</section>
			<SectionContact />
		</>
	);
}