import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';
import ContactInfo from './ContactInfo';
import logo from '../imgs/logo.png';

export default function Footer() {
	const currentDate = new Date().getFullYear();
	return (
		<footer>
			<div className="container">
				<div>
					<img src={logo} className="mb-3" alt="webServices logo" />
					<p>Contactez-moi pour un devis ou des informations</p>
					<p className="mb-3">Ci-dessous les liens vers mes réseaux pour me contacter ou avoir plus d'info sur moi</p>
					<SocialLinks />
				</div>

				<ul>
					<li>
						<h4>Liens rapides</h4>
					</li>
					<li>
						<Link to='/'>Accueil</Link>
					</li>
					<li>
						<Link to='/about'>A propos</Link>
					</li>
					<li>
						<Link to='/services'>Services</Link>
					</li>
					<li>
						<Link to='/projects'>Projets</Link>
					</li>
					<li>
						<Link to='/contact'>Contact</Link>
					</li>
					<li>
						<Link to='/policy'>Mentions légales</Link>
					</li>
				</ul>
				<ContactInfo />
			</div>


			<p className="txt-center">&copy; WebServices {currentDate}. All right reserved. Developed by <Link to="/" className="txt-gradient">Travolgi</Link> Integrated by <span className='txt-gradient'>WebServices</span>.</p>
		</footer>
	);
}