const Projects = [
	{
		img: require('../imgs/projects/project1.jpg'),
		name: 'Creation site web',
		category: 'Site E-commerce',
		url: 'https://maha-bijoux.gregory-etienne.fr/'
	},
	{
		img: require('../imgs/projects/project2.jpg'),
		name: 'BookMyCottage',
		category: 'Service de reservation en ligne',
		url: 'https://bookmycottage.gregory-etienne.fr/'
	},
	{
		img: require('../imgs/projects/project3.jpg'),
		name: "Ferme d'arcan",
		category: 'Site de reservation gite',
		url: 'https://arcan.gregory-etienne.fr/'
	},
	{
		img: require('../imgs/projects/project4.png'),
		name: 'Portfolio',
		category: 'Cv en ligne et portfolio',
		url: 'https://portfolio-js.gregory-etienne.fr/'
	},
	{
		img: require('../imgs/projects/project5.png'),
		name: 'WebServices',
		category: 'Mon entreprise à votre service',
		url: 'https://webservices.gregory-etienne.fr/'
	}
]

export default Projects;
