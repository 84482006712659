import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
	const form = useRef();
	const initValue = { name: '', surname: '', email: '', phone: '', message: '', agree: 'false' };
	const [formVals, setFormVals] = useState(initValue),
		[isAgree, setIsAgree] = useState(false),
		[formErrs, setFormErrs] = useState({}),
		[isSubmit, setIsSubmit] = useState(false),
		[isSent, setIsSent] = useState(false);

	const handleChange = e => {
		const { id, value } = e.target;
		setFormVals({ ...formVals, [id]: value });
	};

	const handleSubmit = e => {
		e.preventDefault();
		setFormErrs(validate(formVals));
		setIsSubmit(true);
	};

	useEffect(() => {
		if (Object.keys(formErrs).length === 0 && isSubmit) {
			emailjs.sendForm('service_1dn3vmn', 'template_xj1a4qm', form.current, 'tRikgZlqQIxgMuGIh')
				.then(result => {
					console.log(result.text);
					setFormVals(initValue);
					setIsSent(true);
					setFormErrs({});
					setIsSubmit(false);
					setIsAgree(false);
				}, error => {
					console.log(error.text);
					setIsSubmit(false);
					setIsSent(false);
				}
				);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formErrs, isSubmit]);

	useEffect(() => {
		if (isSent) {
			const msgSent = setTimeout(() => setIsSent(false), 5000);
			return () => clearTimeout(msgSent);
		}
	}, [isSent]);

	const validate = (vals) => {
		const errors = {};
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

		if (!vals.name) {
			errors.name = 'Insert your name.';
		} else if (vals.name.length < 4) {
			errors.name = 'Enter at least 4 characters.';
		}

		if (!vals.email) {
			errors.email = 'Insert your email.';
		} else if (!regex.test(vals.email)) {
			errors.email = 'Invalid email format!';
		}

		if (vals.agree === 'false') {
			errors.agree = 'You must accept the Privacy Policy.';
		}

		return errors;
	};

	return (
		<form ref={form} onSubmit={handleSubmit}>
			<small>Champs requis *</small>

			<div className="grid g-1">
				<div className="mb-1 w-100">
					<label htmlFor="name" className="sr-only">First Name *</label>
					<input
						id="name"
						name="name"
						type="text"
						placeholder="Nom *"
						required
						value={formVals.name}
						onChange={handleChange}
					/>
					{formErrs.name &&
						<p className="txt-danger fadein">{formErrs.name}</p>
					}
				</div>

				<div className="mb-1 w-100">
					<label htmlFor="surname" className="sr-only">Last Name</label>
					<input
						id="surname"
						name="surname"
						type="text"
						placeholder="Prénom"
						value={formVals.surname}
						onChange={handleChange}
					/>
					{formErrs.surname &&
						<p className="txt-danger fadein">{formErrs.surname}</p>
					}
				</div>

				<div className="mb-2 w-100">
					<label htmlFor="email" className="sr-only">Your Email *</label>
					<input
						id="email"
						name="email"
						type="email"
						placeholder="Email *"
						required
						value={formVals.email}
						onChange={handleChange}
					/>
					{formErrs.email &&
						<p className="txt-danger fadein">{formErrs.email}</p>
					}
				</div>

				<div className="mb-2 w-100">
					<label htmlFor="phone" className="sr-only">Your Phone</label>
					<input
						id="phone"
						name="phone"
						type="tel"
						placeholder="Téléphone"
						value={formVals.phone}
						onChange={handleChange}
					/>
					{formErrs.phone &&
						<p className="txt-danger fadein">{formErrs.phone}</p>
					}
				</div>
			</div>

			<label htmlFor="message" className="sr-only">Your Message *</label>
			<textarea
				className="mb-2"
				id="message"
				name="message"
				rows="6"
				placeholder="Ecrivez votre message ici"
				value={formVals.message}
				onChange={handleChange}
			/>

			<input
				type="checkbox"
				id="agree"
				name="agree"
				value={isAgree === true ? 'false' : 'true'}
				checked={isAgree}
				required
				onClick={() => setIsAgree(!isAgree)}
				onChange={handleChange}
			/>
			<label htmlFor="agree">J'accepte les <Link to="/policy">Mentions légales</Link> *.</label>
			{formErrs.agree &&
				<p className="txt-danger fadein">{formErrs.agree}</p>
			}

			{isSent &&
				<h2 className="txt-success pbk-1 fadein">Message envoyer!</h2>
			}

			<button type="submit" disabled={isSubmit ? true : false}>Envoyer</button>
		</form>
	);
}