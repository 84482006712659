import { Helmet } from 'react-helmet';

export default function Policy() {
	return (
		<>
			<Helmet>
				<title>WebServices: Mentions</title>
			</Helmet>

			<section className="container">
				<h1>Mentions légales</h1>
				<h3>Qui sommes-nous</h3>
				<p>Notre adresse de site Web est : webservices.gregory-etienne.fr .</p>

				<h3>Commentaires</h3>
				<p>Lorsque les visiteurs laissent des commentaires sur le site, nous collectons les données affichées dans le formulaire de commentaires, ainsi que l'adresse IP du visiteur et la chaîne d'agent utilisateur du navigateur pour aider à détecter les spams.</p>

				<h3>Images</h3>
				<p>Si vous téléchargez des images sur le site Web, évitez de télécharger des images contenant des données de localisation intégrées (EXIF GPS). Les visiteurs du site peuvent télécharger et extraire toutes les données de localisation des images sur le site.</p>

				<h3>Cookies</h3>
				<p>Si vous laissez un commentaire sur notre site, vous pouvez choisir d'enregistrer votre nom, votre adresse e-mail et votre site Web dans des cookies. Ils sont utilisés pour votre commodité afin que vous n'ayez pas à saisir à nouveau vos détails lorsque vous laissez un autre commentaire. Ces cookies dureront un an.</p>

				<h3>Combien de temps nous conservons vos données</h3>
				<p>Si vous laissez un commentaire, le commentaire et ses métadonnées sont conservés indéfiniment. Cela nous permet de reconnaître automatiquement et d'approuver tout commentaire ultérieur au lieu de les garder dans une file d'attente de modération.</p>
				<p>Pour les utilisateurs qui s'inscrivent sur notre site Web (le cas échéant), nous stockons également les informations personnelles qu'ils fournissent dans leur profil utilisateur. Tous les utilisateurs peuvent voir, éditer ou supprimer leurs informations personnelles à tout moment (à l'exception de leur nom d'utilisateur qu'ils ne peuvent pas changer). Les administrateurs du site Web peuvent également voir et modifier ces informations.</p>

				<h3>Quels droits avez-vous sur vos données</h3>
				<p>Si vous avez un compte sur ce site ou si vous avez laissé des commentaires, vous pouvez demander à recevoir un fichier exporté des données personnelles que nous détenons à votre sujet, y compris toutes les données que vous nous avez fournies. Vous pouvez également demander que nous supprimions toutes les données personnelles vous concernant. Cela n'inclut pas les données que nous sommes obligés de conserver à des fins administratives, légales ou de sécurité.</p>
			</section>

		</>
	);
}