import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SectionContact from '../components/SectionContact';
import err404 from '../imgs/404.svg'

export default function PageNotFound() {
	return (
		<>
			<Helmet>
				<title>WebServices : perdu</title>
			</Helmet>

			<section className="container grid g-2">
				<img src={err404} alt="404 Page non trouvée" />

				<div>
					<h1>404 Error</h1>
					<h2 className="mb-0">Cette page n'éxiste plus</h2>
					<p className="mb-3">Désoler, nous n'avons pas pu afficher ce que vous rechercher.</p>
					<Link to="/" className="btn">Retour a l'accueil</Link>
				</div>
			</section>

			<SectionContact />
		</>
	);
}