export default function ProjectOverlay({ img, projectName, category, url }) {
	return (
		<article className="project browserbar">
			<img src={img} alt={`Project ${projectName} - Webservice projet`} />

			<div className="info">
				<h2 className="mb-0">{projectName}</h2>
				<p>{category}</p>
				<a target="blank" href={url} className="btn self-end">Voir</a>
			</div>
		</article>
	);
}