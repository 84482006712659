const faqsList = [
	{
		question: "Quel est le prix d'un site web",
		answer: 'Le prix varie en fonction de la complexité du site web et du temps de travail ( 1000€ - 4 000€ en moyenne ). Nous avons des solutions pour tous les budgets, contactez-nous pour en savoir plus.'
	},
	{
		question: 'Dans combien de temps mon site web sera-t-il prêt',
		answer: 'Comme pour la question ci-dessus, le temps est variable en fonction de la complexité du site web et du temps de travail (un mois - 2 mois en moyenne).'
	},
	{
		question: 'Quels sont les couts après la création du site web',
		answer: 'Les couts après la création du site web sont les frais d\'hébergement et de nom de domaine ( 100€ - 200€ par an en moyenne ).'
	},
	{
		question: 'Comment se déroule la création du site web',
		answer: 'Nous commençons par une réunion pour définir vos besoins et vos attentes. Ensuite, nous vous proposons un devis et un planning. Une fois le devis accepté, nous commençons la création de la maquette puis du site web. Nous vous tenons informé de l\'avancement du projet et nous vous demandons votre avis à chaque étape.'
	},
]
export default faqsList