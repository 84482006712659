const Services = [
	{
		icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.738 19.9964C14.8186 19.9988 14.8994 20 14.9806 20C19.3989 20 22.9806 16.4183 22.9806 12C22.9806 7.58172 19.3989 4 14.9806 4C12.4542 4 10.2013 5.17108 8.73522 7H7.51941C3.92956 7 1.01941 9.91015 1.01941 13.5C1.01941 17.0899 3.92956 20 7.51941 20H14.5194C14.5926 20 14.6654 19.9988 14.738 19.9964ZM16.6913 17.721C19.0415 16.9522 20.9806 14.6815 20.9806 12C20.9806 8.68629 18.2943 6 14.9806 6C11.6669 6 8.98059 8.68629 8.98059 12H6.98059C6.98059 10.9391 7.1871 9.92643 7.56211 9H7.51941C5.03413 9 3.01941 11.0147 3.01941 13.5C3.01941 15.9853 5.03413 18 7.51941 18H14.5194C15.0691 18 15.9041 17.9014 16.6913 17.721Z" /></svg>,
		slug: 'Hebergement',
		title: 'Hébergement',
		text: 'Vous souhaitez mettre en ligne votre site web ? Je vous propose des solutions d\'hébergement web adaptées à vos besoins.',
		content: <>
			<h2>Quelle solution ?</h2>
			<p>L'hébergement chez OVH offre plusieurs avantages : des services fiables, une large gamme d'options, et des tarifs compétitifs. En cas de besoin, le support technique est disponible. Avec OVH, la gestion est facilitée grâce à un panneau de contrôle intuitif. La sécurité des utilisateurs est une priorité chez OVH.</p>
			<div className="grid g-2 mb-2">
				<img src={require('../imgs/services/webCloud.jpg')} alt="Web services" />
				<div>
					<h3>SÉCURITÉ</h3>
					<p>Nous mettons en place des mesures de sécurité pour garantir le bon fonctionnement de votre site web hébergé chez OVH.</p>
					<ul className="list">
						<li>Surveillance proactive</li>
						<li>Protection contre les menaces en ligne</li>
						<li>Mises à jour régulières de sécurité</li>
					</ul>
				</div>
			</div>
			<p>Votre site est entre de bonnes mains avec l'hébergement OVH. Profitez d'une expérience sans souci, avec des solutions sécurisées et une infrastructure fiable.</p>
		</>

	},
	{
		icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 9V13.1707C9.83481 13.5825 9 14.6938 9 16C9 17.6569 10.3431 19 12 19C13.6569 19 15 17.6569 15 16C15 14.6938 14.1652 13.5825 13 13.1707V9H11ZM11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16Z" /> <path d="M12 5C15.866 5 19 8.13401 19 12V13H17V12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12V13H5V12C5 8.13401 8.13401 5 12 5Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" /></svg>,
		slug: 'creation',
		title: 'Création',
		text: "Création d'un site web sur mesure, adapté à vos besoins et surtout à votre budget. Il existe des solutions pour tous !",
		content: <>
			<h2>Quelles sont les étapes clef de la création d'un site web ?</h2>
			<p>Nous commençons par une réunion pour définir vos besoins et vos attentes. Ensuite, nous vous proposons un devis et un planning. Une fois le devis accepté, nous commençons la création de la maquette puis du site web. Nous vous tenons informé de l'avancement du projet et nous vous demandons votre avis à chaque étape.</p>
			<div className="grid g-2 mb-2">
				<div>
					<h3>Ce que je propose</h3>
					<ul className="list">
						<li>Site web vitrine</li>
						<li>Site e-commerce</li>
						<li>Pages web dynamiques</li>
						<li>Gestion d'utilisateurs</li>
						<li>Refonte site existant</li>
					</ul>
				</div>
				<img src={require('../imgs/services/surveillance.png')} alt="Services de Surveillance Web" />
			</div>
			<p>Quel que soit votre budget, nous avons des solutions adaptées, allant d'une simple page vitrine créée à partir d'un modèle existant à un site web dynamique doté d'un design unique et de fonctionnalités avancées ! Il est essentiel de garder à l'esprit trois éléments interdépendants qu'on ne peut pas modifier indépendamment les uns des autres :</p>
			<ol className="list" id='explainlist'>
				<li>Le temps attribué au projet</li>
				<li>La technicité du projet</li>
				<li>Son prix</li>
			</ol>
		</>

	},
	{
		icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.1254 13H10.1254V15H14.1254V13Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M8.12537 13C9.22994 13 10.1254 12.1046 10.1254 11C10.1254 9.89543 9.22994 9 8.12537 9C7.0208 9 6.12537 9.89543 6.12537 11C6.12537 12.1046 7.0208 13 8.12537 13ZM8.12537 11.5C8.40151 11.5 8.62537 11.2761 8.62537 11C8.62537 10.7239 8.40151 10.5 8.12537 10.5C7.84922 10.5 7.62537 10.7239 7.62537 11C7.62537 11.2761 7.84922 11.5 8.12537 11.5Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M18.1254 11C18.1254 12.1046 17.2299 13 16.1254 13C15.0208 13 14.1254 12.1046 14.1254 11C14.1254 9.89543 15.0208 9 16.1254 9C17.2299 9 18.1254 9.89543 18.1254 11ZM16.6254 11C16.6254 11.2761 16.4015 11.5 16.1254 11.5C15.8492 11.5 15.6254 11.2761 15.6254 11C15.6254 10.7239 15.8492 10.5 16.1254 10.5C16.4015 10.5 16.6254 10.7239 16.6254 11Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M2.74884 14.6663C3.73056 16.6421 5.76939 18 8.12537 18H16.1254C18.5654 18 20.6652 16.5435 21.6029 14.4525C22.3722 13.9093 22.8746 13.0133 22.8746 12C22.8746 10.9867 22.3722 10.0907 21.6029 9.54753C20.6652 7.45651 18.5654 6 16.1254 6H8.12537C5.76939 6 3.73056 7.3579 2.74884 9.33375C1.78448 9.83263 1.12537 10.8393 1.12537 12C1.12537 13.1607 1.78448 14.1674 2.74884 14.6663ZM8.12537 8H16.1254C17.5088 8 18.7282 8.70234 19.4465 9.76991C19.7227 10.4593 19.8746 11.2119 19.8746 12C19.8746 12.7881 19.7227 13.5407 19.4465 14.2301C18.7282 15.2977 17.5088 16 16.1254 16H8.12537C5.91623 16 4.12537 14.2091 4.12537 12C4.12537 9.79086 5.91623 8 8.12537 8Z" /></svg>,
		slug: 'Sauvegardes',
		title: 'Sauvegardes',
		text: 'Le code produit est versionné et sauvegardé en ligne et localement. En cas de problème, le site peut être restauré ...',
		content: <>
			<h2>Ne pas perdre son site web</h2>
			<p>Le code de votre site web est codé dans les bonnes pratiques, il existe des versions du code, comme ça en cas de piratage et de modification du code, il peut être restauré en quelques clics. Cela permet aussi de pouvoir développer dans le futur de nouvelles fonctionnalités pour votre site web ou service en ligne.</p>
			<div className="grid g-2 mb-2">
				<p>Le code sera sauvegardé à divers endroits pour garantir qu'il ne soit pas perdu. Il existera la version de votre code directement sur la zone d'hébergement, une copie sur Gitlab (solution de versionning), une version sur un disque dur local. Vous pouvez également avoir une copie sur clef USB si vous le souhaitez. </p>
				<img src={require('../imgs/services/save.png')} alt="Web services" />
			</div>
			<p>Avec cette méthode, même un piratage destructeur pourra être annulé en quelques clics, Votre site web sera toujours en mémoire quelque part et pourra être restauré.</p>
		</>
	},
	{
		icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 11C10.2091 11 12 9.20914 12 7C12 4.79086 10.2091 3 8 3C5.79086 3 4 4.79086 4 7C4 9.20914 5.79086 11 8 11ZM8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9Z" /><path d="M11 14C11.5523 14 12 14.4477 12 15V21H14V15C14 13.3431 12.6569 12 11 12H5C3.34315 12 2 13.3431 2 15V21H4V15C4 14.4477 4.44772 14 5 14H11Z" /><path d="M22 11H16V13H22V11Z" /><path d="M16 15H22V17H16V15Z" /><path d="M22 7H16V9H22V7Z" /></svg>,
		slug: 'Base_de_donnees',
		title: 'Base de données',
		text: "Une base de donnée pour gérer par exemple des utilisateurs ou bien un back office pour changer vous-mêmes le contenu de votre site web et l'avoir en main à 100%.",
		content: <>
			<h2>Données en ligne</h2>
			<p>Une base de données et un système de sauvegarde en ligne qui a plusieurs intérêts majeurs, pouvoir utiliser des données dynamiques qui varient en fonction de l'utilisateur du site. Garder en mémoire les utilisateurs ou les actions de votre site web.</p>
			<img src={require('../imgs/projects/project4.png')} alt="Web services" />
			<p>Avoir une base de données peut paraitre un investissement discutable, les utilisateurs vont devoir s'inscrire, avant de pouvoir accéder à toutes les parties du site, mais cela veut aussi pouvoir dire faire du mailing commercial. Indispensable pour un service en ligne comme un tableau de gestion de stock, un planning, un site e-commerce, etc...</p>
		</>
	},
	{
		icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10 11C10 10.4477 10.4477 10 11 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H11C10.4477 12 10 11.5523 10 11Z" /><path d="M11 14C10.4477 14 10 14.4477 10 15C10 15.5523 10.4477 16 11 16H13C13.5523 16 14 15.5523 14 15C14 14.4477 13.5523 14 13 14H11Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M9.09447 4.74918C8.41606 4.03243 8 3.0648 8 2H10C10 3.10457 10.8954 4 12 4C13.1046 4 14 3.10457 14 2H16C16 3.0648 15.5839 4.03243 14.9055 4.74918C16.1782 5.45491 17.1673 6.6099 17.6586 8H19C19.5523 8 20 8.44772 20 9C20 9.55229 19.5523 10 19 10H18V12H19C19.5523 12 20 12.4477 20 13C20 13.5523 19.5523 14 19 14H18V16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H17.6586C16.8349 20.3304 14.6124 22 12 22C9.38756 22 7.16508 20.3304 6.34141 18H5C4.44772 18 4 17.5523 4 17C4 16.4477 4.44772 16 5 16H6V14H5C4.44772 14 4 13.5523 4 13C4 12.4477 4.44772 12 5 12H6V10H5C4.44772 10 4 9.55229 4 9C4 8.44772 4.44772 8 5 8H6.34141C6.83274 6.6099 7.82181 5.45491 9.09447 4.74918ZM8 16V10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10V16C16 18.2091 14.2091 20 12 20C9.79086 20 8 18.2091 8 16Z" /></svg>,
		slug: 'E-commerce',
		title: 'E-commerce',
		text: 'Vous souhaitez vendre vos produits en ligne ? Je vous propose des solutions e-commerce pour rendre vos produits accessible dans le monde entier.',
		content: <>
			<h2>Vendez partout !</h2>
			<p>Pourquoi vendre dans son quartier ou bien sa ville, quand on peut vendre dans le monde entier, l'achat d'un site web e-commerce est plus couteux qu'une vitrine classique. Votre site peut être consulté dans la majorité des pays du monde, pourquoi se priver de clients supplémentaires ?</p>
			<h3>Quoi de plus ?</h3>
			<ul className="list mb-2">
				<li>Visualisation des stocks</li>
				<li>Gestion des clients</li>
				<li>Système de paiement en ligne</li>
				<li>Panneau de gestion</li>
			</ul>
			<p>Obtenez un site vitrine, avec un système complet pour gérer vos clients et vos produits, ajouter, modifier ou supprimer vos produits facilement, gérer vos promotions, tout ça avec le panneau de gestion.</p>
			<img src={require('../imgs/services/produits.png')} className="mb-2" alt="Web services" />
			<p>Avec le CMS WordPress et l'extension WooCommerce, avoir une boutique en ligne devient possible, et vous avez toute la gestion entre vos mains.</p>
		</>
	},
	{
		icon: <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 10.5C19.6569 10.5 21 11.8431 21 13.5V19.5C21 21.1569 19.6569 22.5 18 22.5H6C4.34315 22.5 3 21.1569 3 19.5V13.5C3 11.8431 4.34315 10.5 6 10.5V7.5C6 4.18629 8.68629 1.5 12 1.5C15.3137 1.5 18 4.18629 18 7.5V10.5ZM12 3.5C14.2091 3.5 16 5.29086 16 7.5V10.5H8V7.5C8 5.29086 9.79086 3.5 12 3.5ZM18 12.5H6C5.44772 12.5 5 12.9477 5 13.5V19.5C5 20.0523 5.44772 20.5 6 20.5H18C18.5523 20.5 19 20.0523 19 19.5V13.5C19 12.9477 18.5523 12.5 18 12.5Z" /></svg>,
		slug: 'WebServices',
		title: 'Services Web',
		text: 'Je vous propose une amélioration de votre site : Utilisation de technologie web récente, ajout de fonctionnalités, etc...',
		content: <>
			<h2>Qu'est-ce qu'un service web ?</h2>
			<p>Regardez un service web comme un logiciel que vous avez sur votre ordinateur, sauf qu'avec le service web, la fonctionnalité que vous avez demandée est directement implantée dans votre site web. Pas besoin d'installer quoi que ce soit, il suffit de se rendre à l'URL définit pour utiliser son service.</p>
			<div className="grid g-2 mb-2">
				<div>
					<h3>Comme quoi ?</h3>
					<p>Il y a beaucoup de service différent qui peuvent être implantés dans un site web, vous pouvez même avoir juste un service sans avoir de site web, mais bien sûr celui devra être hébergé.</p>
					<ul className="list">
						<li>Prise de rendez-vous</li>
						<li>Système de calculs</li>
						<li>Programme de fidélité</li>
						<li>Gestion de stocks</li>
					</ul>
				</div>
				<img src={require('../imgs/services/surveillance2.png')} alt="Web services" />
			</div>
			<p>Un service web, c'est comme un abonnement mensuel à un logiciel, mais au lieu de l'installer sur votre ordinateur, vous y accédez via Internet. Le fournisseur gère l'installation, la maintenance et les mises à jour, vous offrant ainsi un accès facile et sans tracas. C'est un moyen pratique d'utiliser des applications sans se soucier des détails techniques.</p>
		</>

	}
]
export default Services;