import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import Faq from '../components/Faq';
import faqsList from '../data/faqs';
import CallToAction from '../components/CallToAction';
import servicesList from '../data/services';
import projectsList from '../data/projects';
import Service from '../components/Service';
import ProjectOverlay from '../components/ProjectOverlay';
import SectionContact from '../components/SectionContact';
import mission from '../imgs/mission.png';


export default function Home() {
	return (
		<>
			<Helmet>
				<title>WebServices, création site web</title>
				<meta name="description" content="Bienvenue sur le site WebServices, je peut vous creer des sites web et des services en ligne" />
				<meta name="keywords" content="developpement, web , ecommerce, saas, creation, site internet, site e-commerce" />
			</Helmet>

			<section id="slider">
				<div className="container">
					<div className="grid gtc-2-1">
						<article>
							<h1>Création site web, <span className="txt-gradient">Technologies récentes</span></h1>
							<h2 className="w-60 fw-400">Augmenter votre visibilité ou vendez vos articles en ligne.</h2>

							<HashLink to="#contact" className="btn">Contactez moi </HashLink>
						</article>
					</div>

					<div className="flex space-between g-2">
						<h2><span className="pictogram" /> Création de site web</h2>
						<h2><span className="pictogram" /> Solution E-commerce</h2>
						<h2><span className="pictogram" /> Services en ligne</h2>
					</div>
				</div>
			</section>

			<section>
				<span className="sr-only offset-nav" id="mission" />
				<div className="container grid g-2">
					<div className="print flex">
						<img src={mission} alt="Homme qui travail sur son ordinateur" />
					</div>

					<article>
						<h1>Ma <span className="txt-gradient">Mission</span></h1>
						<p>Bienvenue chez Webservices. Je peux vous accompagner vers un projet de site web, qu'il soit simple et destiné à une vitrine publicitaire en ligne, ou un site e-commerce pour booster vos ventes en rendant vos produits accessibles dans le monde entier.</p>
						<p className="mb-3">Si vous avez déjà un site web et vous souhaitez le faire évoluer, je peux créer des services pour vous afin d'automatiser certaines tâches (prise de rendez-vous, réservations, etc...) et rendre votre site plus modulable et dynamique avec la gestion d'utilisateurs ou de mails.</p>


						<Link to="/about" className="btn">Découvrir plus</Link>
					</article>
				</div>
			</section>

			<span className="sr-only offset-nav" />
			<section className="accent-start">
				<div className="container">
					<h1 className="txt-gradient">Mes qualités</h1>

					<div className="flex g-1 space-between mb-4">
						<h2><span className="pictogram-top" /> Dynamique</h2>
						<h2><span className="pictogram-top" /> Coopératif</h2>
						<h2><span className="pictogram-top" /> A l'écoute</h2>
						<h2><span className="pictogram-top" /> Motivé</h2>
						<h2><span className="pictogram-top" /> Disponible</h2>
					</div>

					<div className="grid g-2 gtc-1-2-1 mb-4">
						<h2 className="self-start mb-0"> Pourquoi me choisir ?</h2>

						<div>
							<p>Avec moi, vous saurez à quoi vous attendre. Vous aurez une maquette de votre site ou de vos produits avant même que le code ne soit tapé, Par la suite, vous pourrez à tout moment voir les évolutions grâce à un site de production mis à disposition dès le départ du projet. Vous pourrez même faire des retours en direct sur un tableau dynamique mis à disposition pour voir le travail en cours et proposer des choses. </p>
							<p className="mb-0">Le budget sera défini au plus juste selon les critères suivants : Volonté du client, temps attribué pour le projet, cout et ressources, un équilibre permanent devra être maintenu entre ces trois. Si l'on bouge l'un, les trois autres bougent aussi.</p>
						</div>

						<Link to="/services" className="btn self-end">Nos services</Link>
					</div>

					<div className="grid gtc-3 g-2">
						{servicesList.map(service =>
							<Service
								key={service.slug}
								icon={service.icon}
								slug={service.slug}
								title={service.title}
								text={service.text}
							/>
						)}
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="flex g-2 space-between">
						<h1>Mes <span className="txt-gradient">Projets</span></h1>
						<Link to="projets" className="btn mb-3">En voir plus</Link>
					</div>

					<div className="grid g-3">
						{projectsList.slice(0, 4).map((project, idx) =>
							<ProjectOverlay
								key={idx}
								img={project.img}
								projectName={project.name}
								category={project.category}
								url={project.url}
							/>
						)}
					</div>
				</div>
			</section>
			<section className="container">
				<h1 className="txt-center mb-4">Questions <span className="txt-gradient">fréquentes</span></h1>

				<div className="grid g-2 mb-8">
					<article>
						{faqsList.map((faq, idx) =>
							<Faq
								key={idx}
								question={faq.question}
								answer={faq.answer}
							/>
						)}
					</article>
					<ul>
						<li>
							<h3 className='mb-3'>Services proposés :</h3>
						</li>
						<li>
							<h2><span className="pictogram" /> Création site web</h2>
						</li>
						<li>
							<h2><span className="pictogram" /> hébergement</h2>
						</li>
						<li>
							<h2 className="mb-0"><span className="pictogram" /> Maintenance</h2>
						</li>
					</ul>
				</div>
				<section className="bg-line">
					<div className="container txt-center">
						<h3>FAITES-VOUS UN NOM SUR LE WEB</h3>
						<h1 className="fs-100">Booster votre visibilité</h1>
					</div>
				</section>

				<CallToAction
					icon={<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" /></svg>}
					text="La création d'un site web en coopération"
					btnLabel="Contactez moi maintenant"
					btnUrl="/contact"
				/>
			</section>



			<span className="sr-only offset-nav" id="contact" />
			<SectionContact />
		</>
	);
}
